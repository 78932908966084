.mainContainer {
    border-radius: 4px;
    margin: 10px;
    background-color: #ffffff;
    padding: 10px;
}
.mainContainer :hover{
    background-color: rgb(241, 244, 252);
}
.idlink {
    color: #0000ff;
    width: max-content;
    font-size: 18px;
    display: block;
    cursor: pointer;
}

.idlink:hover {
    text-decoration: underline;
    color: rgb(13, 13, 165)
}

.HeadingValue {
    margin: 5px 10px 0 0;
    display: inline-block;
    vertical-align: bottom;
}


.heading {
    font-size: 13px;
    color: black;
    display: inline-block;
    font-weight: bold;
}

.value {
    margin-left: 5px;
    color: black;
    font-size: 13px;
}

.attachment {
    background-color: #f3f3f3;
    width: fit-content;
    float: right;
    padding: 6px;
    border-radius: 7px;
    cursor: 'pointer'
}

.attachment_avatar {
    border-radius: 10%;
    max-width: 30px;
    max-height: 30px;
}