.k-filter-row th{
    padding:10px 0px 10px 8px !important;
}
.k-filtercell-operator .k-button-icon{
    display:none
}
.k-select{
    padding:8px 0px !important
}
/* .k-animation-container-shown{
    width: fit-content !important; 
}
.k-popup{
    width: fit-content !important; 
} */